import '../../utils/marketingPageDataFragment';

import {
  GreenBlob,
  GreenSplatsComplete,
  RedSplatsComplete,
  TealBall,
  TealBlob,
  YellowBeanSplat
} from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';
import MediaQuery from 'react-responsive';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import CertifiedPartnersListModule, { CertifiedPartnersListDataPropsObject } from '../../components/CertifiedPartnersListModule';
import CommunityModule, { CommunityDataPropsObject } from '../../components/CommunityModule';
import CopyBlockModule, { CopyBlockDataPropsObject } from '../../components/CopyBlockModule';
import FaqModule, { FaqModuleDataPropsObject } from '../../components/FaqModule';
import FeaturedTemplatesModule, { FeaturedTemplatesDataPropsObject } from '../../components/FeaturedTemplatesModule';
import HeroModule, { HeroDataPropsObject } from '../../components/HeroModule';
import HowToOrderedListModule, { HowToOrderedListDataPropsObject } from '../../components/HowToOrderedListModule';
import PitchModule, { PitchDataPropsObject } from '../../components/PitchModule';
import SuccessStoryModule, { SuccessStoryDataPropsObject } from '../../components/SuccessStoryModule';
import TipsListTabsModule, { TipsListTabsDataPropsObject } from '../../components/TipsListTabsModule';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import setHeroVideoSchemaInfo from '../../utils/setHeroVideoSchema';
import {
  community as communityClass,
  copyBlock as copyBlockClass,
  featuredTemplates as featuredTemplatesClass,
  greenBlob,
  greenSplatsComplete,
  hero as heroClass,
  htol,
  partners as partnersClass,
  pitch as pitchClass,
  redSplatsComplete,
  successStory as successStoryClass,
  tealBall,
  tealBlob,
  tipsListTabs as tipsListTabsClass,
  yellowBeanSplat
} from './MarketingJobsToBeDonePage.module.css';

export default function MarketingJobsToBeDonePage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;
  const SEOInfo = {
    title         : page.title,
    description   : description && description.description,
    slug          : page.slug,
    canonicalSlug : canonicalUrlPage && canonicalUrlPage.slug,
    noIndex       : page.noIndex
  };

  const {
    certifiedPartnersList,
    community,
    copyBlock,
    featuredTemplates,
    hero,
    howToOrderedList,
    frequentlyAskedQuestionsFaq,
    pitch,
    successStory,
    tipsListTabs
  } = componentsQueryResultToData(components);

  if (imageFieldName && imageFieldName.file && imageFieldName.file.url) {
    SEOInfo.image = imageFieldName.file.url;
  }

  SEOInfo.heroVideoSchemaInfo = setHeroVideoSchemaInfo(hero);

  // Need to decide if we need CPL to be optional, maybe just add a boolean field to the page content model
  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <HeroModule className={heroClass} data={hero} />
      <CertifiedPartnersListModule className={partnersClass} data={certifiedPartnersList} />
      <CopyBlockModule className={copyBlockClass} data={copyBlock} />
      <FeaturedTemplatesModule className={featuredTemplatesClass} data={featuredTemplates} />
      <TipsListTabsModule className={tipsListTabsClass} data={tipsListTabs} />
      {successStory && (
        <SuccessStoryModule
          blobs={(
            <LazyLoad once>
              <MediaQuery maxWidth={767}>
                <TealBlob className={tealBlob} />
              </MediaQuery>
            </LazyLoad>
          )}
          className={successStoryClass}
          data={successStory}
          layout="asset-left"
        />
      )}
      <HowToOrderedListModule className={htol} data={howToOrderedList} />
      {frequentlyAskedQuestionsFaq && (
        <FaqModule
          data={frequentlyAskedQuestionsFaq}
          hasMarginBottom
        />
      )}
      <PitchModule
        blobs={(
          <>
            <LazyLoad once>
              <GreenSplatsComplete className={greenSplatsComplete} />
              <TealBall className={tealBall} />
              <YellowBeanSplat className={yellowBeanSplat} />
              <MediaQuery minWidth={768}>
                <GreenBlob className={greenBlob} />
                <RedSplatsComplete className={redSplatsComplete} />
              </MediaQuery>
            </LazyLoad>
          </>
        )}
        className={pitchClass}
        data={pitch}
      />
      <CommunityModule className={communityClass} data={community} />
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingJobsToBeDonePageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;

MarketingJobsToBeDonePage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCertifiedPartnersList']),
          ...CertifiedPartnersListDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCommunity']),
          ...CommunityDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCopyBlock']),
          ...CopyBlockDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentFeaturedTemplates']),
          ...FeaturedTemplatesDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentFrequentlyAskedQuestionsFaq']),
          ...FaqModuleDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHero']),
          ...HeroDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHowToOrderedList']),
          ...HowToOrderedListDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentPitch']),
          ...PitchDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentSuccessStory']),
          ...SuccessStoryDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentTipsListTabs']),
          ...TipsListTabsDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
