import { CertifiedPartnersList } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import CertifiedPartnerModule, { CertifiedPartnerDataProps } from '../CertifiedPartnerModule';
import { container, marginContainer } from './CertifiedPartnersListModule.module.css';

export default function CertifiedPartnersListModule({
  className,
  data
}) {
  const {
    certifiedPartner,
    hasMarginBottom,
    sentenceCaseTitle,
    title
  } = data;

  const classes = classNames(className, container, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <CertifiedPartnersList
      body={sentenceCaseTitle}
      className={classes}
      tagline={title}
    >
      {certifiedPartner.map((item) => <CertifiedPartnerModule key={uniqueId(item.iconLabel)} data={item} />)}
    </CertifiedPartnersList>
  );
}

export const CertifiedPartnersListDataPropsObject = {
  certifiedPartner  : PropTypes.arrayOf(CertifiedPartnerDataProps).isRequired,
  hasMarginBottom   : PropTypes.bool,
  sentenceCaseTitle : PropTypes.string,
  title             : PropTypes.string
};

CertifiedPartnersListModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(CertifiedPartnersListDataPropsObject).isRequired
};

CertifiedPartnersListModule.defaultProps = {
  className : null
};

export const CertifiedPartnersListModuleData = graphql`
  fragment CertifiedPartnersListModuleData on ContentfulComponentCertifiedPartnersList {
    certifiedPartner {
      ...CertifiedPartnerModuleData
    }
    hasMarginBottom
    sentenceCaseTitle
    title
   }
`;
