/* eslint-disable react/no-danger */
import { Faq } from '@animoto/components';
import { trackUnstructDataEvent } from '@animoto/utils/analytics';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {
  centeredContainer,
  marginBottom
} from './FaqModule.module.css';

export default function FaqModule({
  className,
  data,
  hasMarginBottom: hasMarginBottomProp
}) {
  const {
    hasMarginBottom: hasMarginBottomFromData,
    faqItem,
    splitColumnLayout,
    title
  } = data;
  const hasMarginBottom = hasMarginBottomProp || hasMarginBottomFromData;
  const faqDataList = faqItem.map(({ question, faqItemMarkdown }) => ({
    title : question,
    html  : <div dangerouslySetInnerHTML={{ __html : faqItemMarkdown.childMarkdownRemark.html }} /> // eslint-disable-line react/no-danger
  }));

  const containerStyles = classNames(
    className,
    centeredContainer,
    { [marginBottom] : hasMarginBottom }
  );

  function trackQuestionToggle(details) {
    const ctaId = 'faq';
    const { ctaAction, ctaText } = details;
    const ctaTarget = window.location.pathname;
    trackUnstructDataEvent('callToAction', {
      ctaId,
      ctaAction : ctaAction === 'open' ? 'show-cta' : 'click-cta',
      ctaTarget,
      ctaText
    });
  }
  const mainEntity = faqItem.map(({
    question: name,
    faqItemMarkdown: {
      childMarkdownRemark: {
        html: text
      }
    } }) => ({
    '@type'        : 'Question',
    name,
    acceptedAnswer : {
      '@type' : 'Answer',
      text
    }
  }));
  const ld = {
    '@context' : 'http://schema.org',
    '@type'    : 'FAQPage',
    mainEntity
  };
  return (
    <>
      <script dangerouslySetInnerHTML={{ __html : JSON.stringify(ld) }} type="application/ld+json" />
      <Faq
        className={containerStyles}
        dataList={faqDataList}
        onClickCta={trackQuestionToggle}
        splitColumnLayout={splitColumnLayout}
        titleCopy={title}
      />
    </>
  );
}

export const FaqModuleDataPropsObject = {
  faqItem : PropTypes.arrayOf(PropTypes.shape({
    faqItemMarkdown : PropTypes.shape({
      childMarkdownRemark : PropTypes.shape({
        html : PropTypes.node
      })

    }).isRequired,
    question : PropTypes.string
  })).isRequired,
  hasMarginBottom : PropTypes.bool,
  title           : PropTypes.string
};

FaqModule.propTypes = {
  className       : PropTypes.string,
  data            : PropTypes.shape(FaqModuleDataPropsObject).isRequired,
  hasMarginBottom : PropTypes.bool
};

FaqModule.defaultProps = {
  className       : null,
  hasMarginBottom : false
};

export const FaqModuleData = graphql`
  fragment FaqModuleData on ContentfulComponentFrequentlyAskedQuestionsFaq {
    hasMarginBottom
    faqItem {
        faqItemMarkdown {
          childMarkdownRemark {
            html
          }
        }
        question
      }
    title
    splitColumnLayout
  }
`;
