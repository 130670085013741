// extracted by mini-css-extract-plugin
export var community = "MarketingJobsToBeDonePage-module--community--2-s2z";
export var copyBlock = "MarketingJobsToBeDonePage-module--copyBlock--13PGp";
export var partners = "MarketingJobsToBeDonePage-module--partners--1coOw";
export var pitch = "MarketingJobsToBeDonePage-module--pitch--2NPi4";
export var successStory = "MarketingJobsToBeDonePage-module--successStory--87JMr";
export var htol = "MarketingJobsToBeDonePage-module--htol--2BJe1";
export var featuredTemplates = "MarketingJobsToBeDonePage-module--featuredTemplates--15udl";
export var tipsListTabs = "MarketingJobsToBeDonePage-module--tipsListTabs--1sXyq";
export var tealBlob = "MarketingJobsToBeDonePage-module--tealBlob--1s7QC";
export var greenSplatsComplete = "MarketingJobsToBeDonePage-module--greenSplatsComplete--1j5Mj";
export var yellowBeanSplat = "MarketingJobsToBeDonePage-module--yellowBeanSplat--22k82";
export var tealBall = "MarketingJobsToBeDonePage-module--tealBall--2N0Pd";
export var hero = "MarketingJobsToBeDonePage-module--hero--DGYzp";
export var greenBlob = "MarketingJobsToBeDonePage-module--greenBlob--26VEv";
export var redSplatsComplete = "MarketingJobsToBeDonePage-module--redSplatsComplete--GpVrA";